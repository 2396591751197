import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'

const applicationSettings = {
  clientId: '384fNwGKoPBzUuQPTRaprXo6sFfKy98t',
  adConfiguration: [
    {
      client: 'ca-pub-3373276592006817',
      slot: '6052321469',
      space: 'match-score',
      format: 'auto',
    },
  ],
}

export const widgetScript = () => `
(function (w, d, s) {
    if (w.SDW) console.error('SDW widget already included');
    w.SDW = {}, w.SDW._r = s; methods = ['mount', 'unmount', 'addWidget', 'updateWidget', 'removeWidget'];
    w.SDW._q = []; methods.forEach(method => w.SDW[method] = function () { w.SDW._q.push([method, arguments]) });
    var script = d.createElement('script'); script.async = 1; script.src = s;
    var before = d.getElementsByTagName('script')[0]; before.parentNode.insertBefore(script, before);
})(window, document, '${'https://widgets.shadow.gg/realtime2/scriptLoader.js'}');
window.SDW.mount(${JSON.stringify(applicationSettings)});
window.SDW.addWidget({
    containerId: 'custom-shadow',
    settings: { userDidConsent: true, elements:["RealtimeMap","MapName","TeamStats","PlayerStats","RoundScore","Bans"]},
    type: 'calendar',
});
`

const WidgetPage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()

  useEffect(() => {
    if (!window.SDW) {
      const script = document.createElement('script')
      script.setAttribute('id', 'sdw-script')
      script.innerHTML = widgetScript()
      script.async = false
      document.body.appendChild(script)
    } else {
      window.SDW.mount(applicationSettings)
      window.SDW.addWidget({
        containerId: 'custom-shadow',
        settings: {
          userDidConsent: true,
          elements: [
            'RealtimeMap',
            'MapName',
            'TeamStats',
            'PlayerStats',
            'RoundScore',
            'Bans',
          ],
        },
        type: 'calendar',
      })
    }

    return () => {
      if (window.SDW) {
        window.SDW.removeWidget('custom-shadow')
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title='Esports Widget' />
      <h2 style={{ textAlign: 'center', margin: '20px auto' }}>
        Esports Live Widget
      </h2>
      <div
        id='custom-shadow'
        style={{
          margin: '20px auto',
          width: '80%',
          height: '80vh',
          maxWidth: '528px',
        }}
      />
      <div
        style={{
          height: '120px',
          width: '100%',
          backgroundColor: 'var(--sdw-background)',
        }}
      />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetPage)
